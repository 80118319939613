// React
import { useState, useEffect, useCallback } from 'react'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText,
  Label,
  Switch,
  Tab,
  Table,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminFulfillmentTrackerListAPIRequest,
  MainStemAPIControllersAdminFulfillmentTrackerListTrackedFulfillment,
  MainStemSharpHelpersPaginateFilterDetail
} from 'api-new'
// Global Components
import { InvoiceProblems, NotesList, ShipmentTrackingInvoiceList } from 'components'
// Local - Components
import { FulfillmentTrackerAllCounts, FulfillmentTrackerAllFilters } from './components'
// Local - Table Column Definitions
import { invoiceTrackerColumns } from './columns'
// Local - Types
import { IFulfillmentTrackerInvoicesAllFilters } from './types'

const FulfillmentTrackerInvoicesAll: React.FC = () => {
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State - Counts
  const [totalIssues, setTotalIssues] = useState(0)
  const [totalFulfilled, setTotalFulfilled] = useState<number>(0)
  const [totalFulfillments, setTotalFulfillments] = useState<number>(0)
  const [totalNoProblems, setTotalNoProblems] = useState<number>(0)
  const [totalProblems, setTotalProblems] = useState<number>(0)
  const [totalUnfulfilled, setTotalUnFilfilled] = useState<number>(0)
  const [totalUniqueOrders, setTotalUniqueOrders] = useState<number>(0)
  // View State - Data
  const [expandedRows, setExpandedRows] = useState<any[]>([])
  const [trackedInvoices, setTrackedInvoices] = useState<
    MainStemAPIControllersAdminFulfillmentTrackerListTrackedFulfillment[]
  >([])
  // Filters
  const [filters, setFilters] = useState<IFulfillmentTrackerInvoicesAllFilters>()

  const loadPageOfData = useCallback(() => {
    setLoading(true)
    setTrackedInvoices([])
    setExpandedRows([])

    const filtersForAPI = {}

    if (filters?.customerID) {
      filtersForAPI['customerID'] = {
        filterVal: filters.customerID,
        comparator: 'LIKE',
        filterType: 'TEXT'
      } as MainStemSharpHelpersPaginateFilterDetail
    }

    if (filters?.customerName) {
      filtersForAPI['customerName'] = {
        filterVal: filters.customerName,
        comparator: 'LIKE',
        filterType: 'TEXT'
      } as MainStemSharpHelpersPaginateFilterDetail
    }

    if (filters?.supplierID) {
      filtersForAPI['supplierID'] = {
        filterVal: filters.supplierID,
        comparator: 'LIKE',
        filterType: 'TEXT'
      } as MainStemSharpHelpersPaginateFilterDetail
    }

    if (filters?.supplierName) {
      filtersForAPI['supplierName'] = {
        filterVal: filters.supplierName,
        comparator: 'LIKE',
        filterType: 'TEXT'
      } as MainStemSharpHelpersPaginateFilterDetail
    }

    if (filters?.orderID) {
      filtersForAPI['orderID'] = {
        filterVal: filters.orderID.toString(),
        comparator: 'LIKE',
        filterType: 'TEXT'
      } as MainStemSharpHelpersPaginateFilterDetail
    }

    if (filters?.fulfillmentID) {
      filtersForAPI['fulfillmentID'] = {
        filterVal: filters.fulfillmentID.toString(),
        comparator: 'LIKE',
        filterType: 'TEXT'
      } as MainStemSharpHelpersPaginateFilterDetail
    }

    const apiRequest = {
      filters: filtersForAPI,
      page: 1,
      pageSize: 1000000
    } as MainStemAPIControllersAdminFulfillmentTrackerListAPIRequest

    MainStemApi.api.fulfillmentTrackerList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setTrackedInvoices(apiResponse.data.fulfillments || [])
        setTotalFulfilled(apiResponse.data.totalFulfilled || 0)
        setTotalFulfillments(apiResponse.data.totalFulfillments || 0)
        setTotalNoProblems(apiResponse.data.totalNoProblems || 0)
        setTotalProblems(apiResponse.data.totalProblems || 0)
        setTotalUnFilfilled(apiResponse.data.totalUnfulfilled || 0)
        setTotalUniqueOrders(apiResponse.data.totalUniqueOrders || 0)
        setLoading(false)
      }
    })
  }, [])

  /**
   * Fires anytime the "filters" change
   */
  useEffect(() => {
    loadPageOfData()
  }, [filters])

  const loadSingleRow = (row, rowIndex) => {
    setLoading(true)

    const apiRequest = {
      'apiRequest.fulfillmentID': row.fulfillmentId
    }

    MainStemApi.api
      .fulfillmentTrackerDetails(apiRequest)
      .then((apiResponse) => {
        const copyOfInvoices = [...trackedInvoices]
        if (apiResponse.data.wasSuccessful && apiResponse.data.fulfillment) {
          copyOfInvoices[rowIndex] = apiResponse.data.fulfillment
        }
        copyOfInvoices[rowIndex].loading = false
        setTrackedInvoices(copyOfInvoices)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const invoiceUpdate = (invoice, rowIndex) => {
    const apiRequest = {
      fulfillmentId: invoice.fulfillmentId,
      fulfillmentOrderNumber: invoice.fulfillmentOrderNumber,
      hasProblem: invoice.hasProblem
    }
    setLoading(true)
    MainStemApi.api.fulfillmentTrackerUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Quick update invoice successful.')
      } else {
        toast.error('Quick update invoice failed.')
      }
      loadSingleRow(invoice, rowIndex)
    })
  }

  const expandRowOptions = {
    renderer: (row, rowIndex) => {
      return (
        <>
          <Tabs>
            <Tab length={totalIssues} title='Invoice Problems'>
              <InvoiceProblems
                invoiceID={row.fulfillmentId}
                onReload={() => {
                  loadSingleRow(row, rowIndex)
                }}
                programType={row.programType}
                setTotalIssues={setTotalIssues}
              />
            </Tab>
            <Tab title='Invoice Notes'>
              <NotesList
                allowPrivate
                documentID={row.fulfillmentId}
                documentType='fulfillment'
                onActionApplied={() => {
                  console.log('Action applied!', row, rowIndex)
                  // Since we use Invoice notes for fulfillment Note now.
                  loadSingleRow(row, rowIndex)
                }}
                title='Invoice Notes'
              />
            </Tab>
            <Tab title='Update Invoice'>
              <Card>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <Button
                        loading={row.loading}
                        onClick={() => {
                          const copyOfInvoices = [...trackedInvoices]
                          copyOfInvoices[rowIndex].loading = true
                          setTrackedInvoices(copyOfInvoices)
                          invoiceUpdate(row, rowIndex)
                        }}
                      >
                        Update
                      </Button>
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Update Invoice - {row.invoiceId}</CardHeaderTitle>}
                />
                <CardBody>
                  <Grid>
                    <GridItem colSpan={6}>
                      <Label>Invoice Has Problems:</Label>
                      <Switch
                        isChecked={row.hasProblem}
                        offText='No Problems'
                        onChange={({ target }) => {
                          // row.fulfillmentOrderNumber = target.value
                          const copyOfInvoices = [...trackedInvoices]
                          copyOfInvoices[rowIndex].hasProblem = target.checked
                          setTrackedInvoices(copyOfInvoices)
                        }}
                        onText='Has Problems'
                      />
                    </GridItem>
                    <GridItem colSpan={6}>
                      <InputText
                        label='Supplier Order ID'
                        onChange={(newValue) => {
                          // row.fulfillmentOrderNumber = target.value
                          const copyOfInvoices = [...trackedInvoices]
                          copyOfInvoices[rowIndex].fulfillmentOrderNumber = newValue
                          setTrackedInvoices(copyOfInvoices)
                        }}
                        value={row.fulfillmentOrderNumber}
                      />
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </Tab>
            <Tab title='Shipment Tracking'>
              <ShipmentTrackingInvoiceList
                invoiceID={row.fulfillmentId}
                onActionApplied={() => {
                  loadSingleRow(row, rowIndex)
                }}
              />
            </Tab>
            <Tab title='Order Notes'>
              <NotesList allowPrivate documentID={row.orderId} documentType='order' title='Orders Notes' />
            </Tab>
          </Tabs>
          <hr />
          <br />
        </>
      )
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expanded: expandedRows,
    onExpand: (row, isExpand) => {
      if (isExpand) {
        setExpandedRows([row.fulfillmentId])
      } else {
        setExpandedRows(expandedRows.filter((x) => x !== row.fulfillmentId))
      }
    },
    // Not sure how to have the show expand + not show the top level expander.. so this overrides it.
    expandHeaderColumnRenderer: () => {
      return <></>
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <Button color='danger' icon={theme.icons.cancel}>
            Cancel Edit
          </Button>
        )
      }
      return (
        <Button color='primary' icon={theme.icons.edit}>
          Edit Details
        </Button>
      )
    }
  }

  return (
    <>
      <div>
        <FulfillmentTrackerAllCounts
          totalFulfilled={totalFulfilled}
          totalFulfillments={totalFulfillments}
          totalNoProblems={totalNoProblems}
          totalProblems={totalProblems}
          totalUnfulfilled={totalUnfulfilled}
          totalUniqueOrders={totalUniqueOrders}
        />
        <br />
        <FulfillmentTrackerAllFilters onFiltersUpdated={setFilters} />
        <br />
        <Table
          columns={invoiceTrackerColumns()}
          data={trackedInvoices}
          expandRow={expandRowOptions}
          keyField='fulfillmentId'
          loading={loading}
          overrideCsvExport={() => {
            window.alert('Export invoice data from PowerBI.')
          }}
          title='Invoice Results'
        />
      </div>
    </>
  )
}

export { FulfillmentTrackerInvoicesAll }
